<template>
    <div class="courseDetail">
      <div class="top-info">
        <div class="top-content">
          <div class="title-box">
            <div @click="$router.back()">
              <svg-icon icon-class="backIcon" class-name="backIcon"></svg-icon>
            </div>
            <img
              src="../../../assets/images/learn/course-detail-title.png"
              alt=""
            />
          </div>
          <div class="course-info" v-if="taskDetail">
            <div class="info-left">
              <div class="wdTag" v-if="taskDetail" :style="`background:url(https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/plan5.png) no-repeat left top;background-size:cover`">
                <p class="text">计划</p>
              </div>
              <img :src="taskDetail?.coverImage" class="cover" alt="" />
            </div>
            <div class="info-right">
              <p class="label" v-if="taskDetail.contentType">
                {{ ConstData.taskType[taskDetail.contentType].text }}
              </p>
              <p class="title">{{ taskDetail.name }}</p>
              <div
                class="taskItemTypeList"
                v-if="taskDetail.courseItemTypeList && taskDetail.type === 1"
              >
                <p
                  v-for="item in taskDetail.courseItemTypeList"
                  v-show="item.type != 1"
                  :key="item.type"
                >
                  <span>{{ ConstData.taskType[item.type].text }}：</span>
                  <span>{{ item.count }}</span>
                </p>
              </div>
              <p class="time">
                计划周期：{{
                  taskDetail.studyType === 1
                    ? `${taskDetail.startTime
                        ?.split(" ")[0]
                        .replace(/-/g, ".")} - ${taskDetail.endTime
                        ?.split(" ")[0]
                        .replace(/-/g, ".")}`
                    : "长期有效"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-tree">
        <div class="tab">
          <p
            class="item"
            :class="{ 'active': tabIndex === item.id }"
            @click="jump(item.id)"
            v-for="item in tabList"
            :key="item.id"
          >
            {{ item.name }}
          </p>
        </div>
        <div class="hid-scroll-bar">
          <div class="scroll-box" id="scroll-box">
            <div v-if="tabIndex == 'anchor1'" class="introduction" id="anchor1">
              <div v-html="taskDetail?.description"></div>
            </div>
            <div v-if="tabIndex == 'anchor2'" class="catalogue" id="anchor2">
              <p class="title">课程目录</p>
              <ul class="courseList">
                <li class="item" v-for="(item,index) in treeData" :key="index" @click.stop="goDetail(item)">
                    <img src="../../../assets/images/learn/new-icon.png" v-if="item.isNew" class="new-icon" alt="" />
                    <div class="item-left">
                      <img :src="item.taskDetailWithoutLogin?.coverImage" class="cover" alt="" />
                    </div>
                    <div class="info">
                      <div>
                        <p class="name">
                          <span>{{ item.taskDetailWithoutLogin?.name }}</span>
                        </p>
                        <p class="timesdate">培训时间：{{$dayjs(item.trainStartDate).format("YYYY.MM.DD") }}-{{$dayjs(item.trainEndDate).format("YYYY.MM.DD") }}</p>
                      </div>
                      <div class="progress">
                        <el-progress color="$WDPrimaryColor" define-back-color="#E9E9E9" text-color="$WDPrimaryColor" :show-text="false"
                          :percentage="+item.taskDetailExt?.studyProcess || 0"></el-progress>
                        <span class="hour">已学习{{ +item.taskDetailExt?.studyProcess || 0 }}%&nbsp;&nbsp;&nbsp;{{ formateTime(item.taskDetailExt?.learnTotalTime) || 0 }} h</span>
                      </div>
                    </div>
                    <div class="botton" v-if="item.studyStatus!=0">
                      <p class="btn" @click.stop="goDetail(item)">
                        {{ item.taskDetailExt?.learnTotalTime > 0 ? "继续学习" : "开始学习" }}
                      </p>
                    </div>
                    <p class="botton botton-lock" v-if="item.studyStatus==0"><svg-icon icon-class="suo"></svg-icon></p>
                </li>
              </ul>
            </div>
            <div v-if="tabIndex == 'anchor3'" class="evaluate" id="anchor3">
              <div class="evaluate-content">
                <h4>1.满意度评价</h4>
                  <ul v-if="!form.stars">
                    <li v-for="(item,index) in evaluateObj" :key="index" :class="{
                      'active':index+1==evaluate
                    }"
                    @click="change(index+1)">
                      <svg-icon :iconClass="item.actsrc" alt="" @click="change(index+1)" v-if="index+1 == evaluate"></svg-icon>
                      <svg-icon :iconClass="item.src" alt="" @click="change(index+1)" v-else></svg-icon>
                      <span>{{ item.name }}</span>
                    </li>
                  </ul>
                  <ul v-else>
                    <li class="active">
                      <svg-icon :iconClass="evaluateObj[form.stars-1]?.actsrc" alt=""></svg-icon>
                      <span>{{ evaluateObj[form.stars-1]?.name }}</span>
                    </li>
                  </ul>
                  <h4>2.意见或建议</h4>
                  <el-form :rules="rules" ref="ruleForm" :model="form">
                    <el-form-item prop="comment">
                      <el-input type="textarea" :disabled="form.stars?true:false" resize="none" maxlength="200" rows="6" v-model="form.comment" placeholder="请留下您对本次培训的宝贵意见或建议"></el-input>
                    </el-form-item>
                    <el-form-item v-if="!form.stars">
                      <button @click="submit" type='button' class="submit-btn">提交</button>
                    </el-form-item>
                  </el-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        evaluate:null,
        form:{
          stars:null
        },
        tabIndex: "anchor2",
        offsetTop: 0,
        treeData: [],
        evaluateObj:[
          {
            name:'非常不满意',
            src:'stars-1',
            actsrc:'stars-1-act'
          },
          {
            name:'不满意',
            src:'stars-2',
            actsrc:'stars-2-act'
          },
          {
            name:'一般',
            src:'stars-3',
            actsrc:'stars-3-act'
          },
          {
            name:'满意',
            src:'stars-4',
            actsrc:'stars-4-act'
          },
          {
            name:'非常满意',
            src:'stars-5',
            actsrc:'stars-5-act'
          }
        ],
        tabList: [
          {
            name: "简介",
            id: "anchor1",
          },
          {
            name: "目录",
            id: "anchor2",
          },
          {
            name: "评价",
            id: "anchor3",
          },
        ],
        taskDetail: {},
        taskRuleInfo: null,
        rules:{
          comment:[{required:true,message:'请输入',trigger:'blur'}]
        }
      };
    },
    created() {
      this.findPlanDetail();
      this.findByVo();
    },
    beforeDestroy() {},
    mounted() {},
    /* eslint-disable */
    computed: {},
    methods: {
      change(data){
        console.log(data);
        this.evaluate = data;
      },
      findByVo(){
        this.$api.plan.findByVo({
          ucode:this.$route.query.ucode
        }).then(res=>{
          if(res.code==0){
            this.form = res.data;
          }
        })
      },
      submit(){
        if(!this.evaluate){
          this.$message.warning('请选择满意度评价');
          return
        }
        this.$refs.ruleForm.validate((valid) => {
          if(valid){
            this.$api.plan.saveEveluate({
              ucode:this.$route.query.ucode,
              ...this.form,
              itemType:1,//计划itemType：1
              stars:+this.evaluate
            }).then(res=>{
              if(res.code==0){
                this.form.stars = this.evaluate;
                this.$message.success('评价成功')
              }
            })
          }
        })
      },
      // 初始化详情
      async findPlanDetail() {
        const params = {
          planUcode: this.$route.query.ucode || null,
        };
        await this.$api.plan
          .findPlanDetail(params)
          .then(async (res) => {
            if (!res.data && res.message === "用户尚未添加到该培训计划") {
              this.$router.go(-1);
              return false;
            }
            if (res.data) {
              this.taskDetail = res.data;
              this.treeData = res.data.planContent;
              /**
               * studyType 学习周期类型 1区间 2购买后参数 3永久有效"
               * （长期有效默认打开的是目录/项目周期开始前是简介，开始后是目录）
               */
              if (this.taskDetail.studyType === 3) {
                this.tabIndex = "anchor2";
              }
              if (
                (this.taskDetail.studyType === 1 ||
                  this.taskDetail.studyType === 2) &&
                new Date().getTime() <=
                  new Date(this.taskDetail.startTime).getTime()
              ) {
                this.tabIndex = "anchor1";
              } else {
                this.tabIndex = "anchor2";
              }
            }
          });
      },
      goDetail(item){
        if(item.studyStatus==0){
          this.$message.warning('还未到开始时间，还不能学习哦~')
          return 
        }
        this.$router.push({
          path:'/learn/course/detail',
          query:{
            id:item.taskDetailWithoutLogin.id,
            type:item.taskDetailWithoutLogin.type
          }
        })
      },
      formateTime(val) {
        if (val) {
          if ((val / 3600).toFixed(2) > 0) {
            return (val / 3600).toFixed(2);
          } else {
            return (val / 3600).toFixed(0);
          }
        } else {
          return val;
        }
      },
      jump(id) {
        this.tabIndex = id;
      },
      scroll() {
        const box = document.getElementById("scroll-box");
        console.log(box.scrollTop, "box");
        console.log(this.offsetTop, "offsetTop==");
        // 若当前设置的滚动高度大于实际滚动的高度，即为锚点跳转，不再设置选中的锚点
        if (this.offsetTop > box.scrollTop) {
          this.offsetTop = 0;
          return;
        }
        let totalH = 0;
        this.tabList.some((anchor) => {
          let scrollItem = document.getElementById(anchor.id); // 锚点对应的模块
  
          totalH = totalH + scrollItem.clientHeight;
          console.log(box.scrollTop, "box.scrollTop");
          console.log(totalH, "totalH===");
          let judge = box.scrollTop < totalH;
          console.log(judge, "judge====");
          if (judge) {
            this.tabIndex = anchor.id;
            return true;
          }
        });
      },
    },
  };
  </script>
  <style
    lang="scss"
    src="ss-pc/src/modules/courseCenter/assets/css/detail.scss"
    scoped
  ></style>
  <style lang="scss" src="ss-pc/src/modules/learn/asset/css/courseDetail.scss" scoped></style>
  <style lang="scss" src="../asset/css/list.scss" scoped></style>
  <style lang="scss" scoped>
    .wdTag{
    position: absolute;
    top: 0px;
    left: 0px;
    width:62px;
    height:30px;
    .text{
        color:#fff!important;
        font-size:12px;
        padding-left:10px;
        line-height:12px;
        margin-top:6px;
        &.employ{
            padding-left:4px;
        }
    }
}
.courseList{
  .item{
    padding-left:0;
    padding-right:0;
    border-bottom: 1px solid #F3F3F3;
    &:nth-last-child(1){
      border: none;
    }
    &:nth-child(1){
      padding-top: 12px;
    }
    margin-bottom: 0;
    img{
      vertical-align: middle;
    }
    .info{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .timesdate{
        font-size: 14px;
        line-height: 14px;
        color: #666;
        margin-top: 12px;;
      }
    }
  }
}
.evaluate{
  width: 822px;
  h4{
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;
    margin-top: 24px;
  }
  ::v-deep .el-textarea__inner{
    border: 2px solid #E3E5F0;
    padding: 16px;
  }
  .submit-btn{
    outline: none;
    border: none;
    appearance: none;
    color: #fff;
    background: $WDPrimaryColor;
    width: 160px;
    height: 42px;
    border-radius: 8px;
    display: block;
    margin: 0 auto;
  }
  ul{
    display: flex;
    li{
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 80px;
      margin-bottom: 24px;
      &.active{
        span{
          color: #FF6632;
        }
      }
      span{
        color: #666;
        font-size: 14px;
        line-height: 14px;
      }
      .icon{
        font-size: 53px;
        margin-bottom: 16px;
      }
    }
  }
}
  </style>